import React from 'react'
import {
  GeneralGrid,
  GeneralGridMobile,
  BureauDeStilHome,
  BureauDeStil,
  BureauDeStilHomeMobile,
  SeasonHome,
  SeasonHomeMobile,
} from '~/utils/styles'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { MobileView } from 'react-device-detect'

const ShopButtom = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              handle
              productType
            }
          }
        }
      }
    `
  )

  return (
    <MobileView>
      <Link
        to={`/products/${allShopifyProduct.edges[0].node.productType}/`}
        style={{
          position: 'fixed',
          left: '12px',
          top: '40%',
          textDecoration: 'none',
        }}
      >
        <BureauDeStil>SHOP</BureauDeStil>
      </Link>
    </MobileView>
  )
}

export default ShopButtom
