import React, { useContext, useEffect, useState } from 'react'
import SEO from '~/components/seo'
import StoreContext from '~/context/StoreContext'
import { BrowserView, MobileView } from 'react-device-detect'
import {
  GeneralGrid,
  GeneralGridMobile,
  BureauDeStilHome,
  BureauDeStil,
  BureauDeStilHomeMobile,
  SeasonHome,
  SeasonHomeMobile,
} from '~/utils/styles'
import Layout from '../layouts'
import Navigation from '../components/Navigation'
import ShopButtom from '../components/Shop/index'
import BackgroundSlider from 'gatsby-image-background-slider'
import { graphql, useStaticQuery, Link } from 'gatsby'

const IndexPage = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const [homeMenuOpen, setHomeMenuOpen] = useState(false)

  return (
    <div>
      <SEO
        title="BDST | BY BEN TAVERNITI | Bureau de Stil"
        keywords={[
          `bureau de Stil`,
          `ben tavertini`,
          `kim kardashian`,
          'LA Fashion',
          'fashion',
          'joyce bonelli',
          'unravel project',
          'trend brand',
          'online shop',
        ]}
      />

      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 5000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
      />
      <BrowserView style={{ height: '100vh' }}>
        <Layout>
          <GeneralGrid>
            <Navigation color={'white'} />
            <BureauDeStilHome>
              <BureauDeStil>BUREAU</BureauDeStil>
              <BureauDeStil>DE</BureauDeStil>
              <BureauDeStil>STIL</BureauDeStil>

              {/* <Link
                style={{
                  position: 'fixed',
                  bottom: '0',
                  height: '10vh',
                  width: '100px',
                }}
                to="/lookbook"
              ></Link> */}
              <Link to="/lookbook">
                <SeasonHome>
                  <BureauDeStil>FALL</BureauDeStil>
                  <BureauDeStil>/WINTER</BureauDeStil>
                  <BureauDeStil>2020</BureauDeStil>
                </SeasonHome>
              </Link>
            </BureauDeStilHome>
          </GeneralGrid>
        </Layout>
      </BrowserView>

      <MobileView>
        <Navigation homeMenuOpen={homeMenuOpen} />
        <GeneralGridMobile style={{ height: '100vh' }}>
          <div style={{ position: 'fixed', left: '12px', top: '12px' }}>
            <BureauDeStilHomeMobile>BUREAU</BureauDeStilHomeMobile>
            <BureauDeStilHomeMobile>DE</BureauDeStilHomeMobile>
            <BureauDeStilHomeMobile>STIL</BureauDeStilHomeMobile>
          </div>
          <ShopButtom />
        </GeneralGridMobile>
        <Link to="/lookbook">
          <div style={{ position: 'fixed', left: '12px', bottom: '12px' }}>
            <SeasonHomeMobile>FALL</SeasonHomeMobile>
            <SeasonHomeMobile>/WINTER</SeasonHomeMobile>
            <SeasonHomeMobile>2020</SeasonHomeMobile>
          </div>
        </Link>
      </MobileView>
    </div>
  )
}

export default IndexPage
